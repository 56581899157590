import { useState, useContext, useEffect, useRef } from 'react'
import './style.css'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Grow from '@mui/material/Grow';
import { Container } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ShieldIcon from '@mui/icons-material/Shield';
//weapon types
import weaponSwordImg from './img/weapon.png'
import weaponAxeImg from './img/axe.png'
import weaponLanceImg from './img/lance.png'
import weaponTomeImg from './img/tome.png'
// movement types
import infantryImg from './img/infantry.png'
import flyerImg from './img/flyer.png'
import armoredImg from './img/armored.png'
import cavImg from './img/cavalry.png'
import Tooltip from '@mui/material/Tooltip';
import { HeroContext } from '../../App';
import { randomHero } from '../heroes';
import PlayAgainButton from '../PlayAgainButton/PlayAgainButton';

const Guess = () => {
    // context
    const [heroGuess] = useContext(HeroContext)

    // local states
    const [colourCorrect, setColourCorrect] = useState(false)
    const [weaponCorrect, setWeaponCorrect] = useState(false)
    const [moveTypeCorrect, setMoveTypeCorrect] = useState(false)
    const [assistCorrect, setAssistCorrect] = useState(false)
    const [specialCorrect, setSpecialCorrect] = useState(false)
    const [a_skillCorrect, setA_SkillCorrect] = useState(false)
    const [b_skillCorrect, setB_SkillCorrect] = useState(false)
    const [c_skillCorrect, setC_SkillCorrect] = useState(false)

    const initialRenderRef = useRef(true)

    const weaponImg = {
        sword: weaponSwordImg,
        axe: weaponAxeImg,
        lance: weaponLanceImg,
        tome: weaponTomeImg,
        dagger: 'Dagger',
        bow: 'Bow',
        staff: 'Staff',
        breath: 'Dragon Breath',
        beast: 'Beast', 
    }

    // take heroGuess values and compare with randomHero values
    // if true, convert local state to true as
    
    useEffect(() => {
        if(heroGuess){
            if (initialRenderRef.current){
                if(heroGuess.colour === randomHero.colour){
                    setColourCorrect(true)
                }
                if(heroGuess.weapon === randomHero.weapon){
                    setWeaponCorrect(true)
                }
                if(heroGuess.movement === randomHero.movement){
                    setMoveTypeCorrect(true)
                }
                if(heroGuess.assist === randomHero.assist){
                    setAssistCorrect(true)
                }
                if(heroGuess.special === randomHero.special){
                    setSpecialCorrect(true)
                }
                if(heroGuess.A === randomHero.A){
                    setA_SkillCorrect(true)
                }
                if(heroGuess.B === randomHero.B){
                    setB_SkillCorrect(true)
                }
                if(heroGuess.C === randomHero.C){
                    setC_SkillCorrect(true)
                }
                initialRenderRef.current = false;
            }
        }
        else{
            setColourCorrect(false)
            setWeaponCorrect(false)
            setMoveTypeCorrect(false)
            setAssistCorrect(false)
            setSpecialCorrect(false)
            setA_SkillCorrect(false)
            setB_SkillCorrect(false)
            setC_SkillCorrect(false)
        }
    }, [heroGuess])

    return (
        <>
            {console.log(heroGuess.movement)}
            <Container 
                maxWidth='lg' 
                sx={{width: '100%', '@media (max-width: 428px)': { width: '75%' }, padding: '15px'}}>
                <div className='guess-container'>
                
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}
                    {...(true ? { timeout: 1000 } : {})}>
                       <div className='guess'>
                        {/* Colour */}
                            {
                                colourCorrect 
                                ? <div style={{background: randomHero.colour, padding: 15, borderRadius: 20}}></div> 
                                : <HelpOutlineIcon />
                            }
                        </div>
                    </Grow>

                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}
                    {...(true ? { timeout: 1500 } : {})}>
                        <Tooltip title={ moveTypeCorrect ? randomHero.movement : "?" } placement='bottom'>
                            <div className='guess'>
                                {/* Colour */}
                                {
                                    moveTypeCorrect ? (
                                        (() => {
                                            switch (heroGuess.movement) {
                                                case "Infantry":
                                                    return <img src={infantryImg} alt={infantryImg} />
                                                case "Armored":
                                                    return <img src={armoredImg} alt={armoredImg} />
                                                case "Flying":
                                                    return <img src={flyerImg} alt={flyerImg} />
                                                case "Cavalry":
                                                    return <img src={cavImg} alt={cavImg} />
                                                default:
                                                    return <HelpOutlineIcon />;
                                            }
                                        })()
                                    ) : (
                                        <HelpOutlineIcon />
                                    )
                                }
                            </div>
                        </Tooltip>
                    </Grow>

                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}
                    { ...(true ? { timeout: 2000 } : {})}>
                        <Tooltip title={ weaponCorrect ? randomHero.weapon : "?" } placement='bottom'>
                            <div className='guess'>
                            {/* Weapon */}
                                {
                                    weaponCorrect 
                                    ? <img src={weaponImg.sword} alt={heroGuess.weapon} />
                                    : <HelpOutlineIcon />
                                }
                            </div>
                        </Tooltip>
                    </Grow>  

                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}
                    { ...(true ? { timeout: 2500 } : {}) }>
                        <Tooltip title={ assistCorrect ? randomHero.assist : "?" } placement='bottom'>
                            <div className='guess'>
                            {/* assist */}
                                {
                                    assistCorrect 
                                    ? <ShieldIcon style={{color: 'green'}} /> 
                                    : <HelpOutlineIcon />
                                }
                            </div>
                        </Tooltip>
                    </Grow>  
                    
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}
                    { ...(true ? { timeout: 3000 } : {}) }>
                        <Tooltip title={ specialCorrect ? randomHero.special : "?" } placement='bottom'>
                            <div className='guess'>
                            {/* special */}
                                {
                                    specialCorrect ? 
                                    <AutoAwesomeIcon style={{color: 'purple'}} />
                                    : <HelpOutlineIcon />
                                }
                            </div>
                        </Tooltip>
                    </Grow>  

                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}
                    { ...(true ? { timeout: 3500 } : {}) }>
                        <Tooltip title={ a_skillCorrect ? randomHero.A : "?" } placement='bottom'>
                            <div className='guess'>
                            {/* A */}
                                {
                                    a_skillCorrect ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <defs>
                                        <linearGradient id="A" gradientTransform="rotate(90)">
                                            <stop offset="0%" stopColor="#ff4b69" />
                                            <stop offset="100%" stopColor="#fff" />
                                        </linearGradient>
                                        </defs>
                                        <text x="7" y="20" style={{ fontSize: '18px', fontWeight: '700' }}>
                                        <tspan fill="url(#A)">A</tspan>
                                        </text>
                                    </svg>
                                    : <HelpOutlineIcon />
                                }
                            </div>
                        </Tooltip>
                    </Grow>  
                
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}
                    { ...(true ? { timeout: 4000 } : {}) }>
                        <Tooltip title={ b_skillCorrect ? randomHero.B : "?" } placement='bottom'>
                            <div className='guess'>
                            {/* B */}
                                {
                                    b_skillCorrect ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <defs>
                                        <linearGradient id="B" gradientTransform="rotate(90)">
                                            <stop offset="0%" stopColor="#006bde" />
                                            <stop offset="100%" stopColor="#fff" />
                                        </linearGradient>
                                        </defs>
                                        <text x="7" y="20" style={{ fontSize: '18px', fontWeight: '700' }}>
                                        <tspan fill="url(#B)">B</tspan>
                                        </text>
                                    </svg>
                                    : <HelpOutlineIcon />
                                }
                            </div>
                        </Tooltip>
                    </Grow> 
                    
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}
                    { ...(true ? { timeout: 4500 } : {}) }>
                        <Tooltip title={ c_skillCorrect ? randomHero.C : "?" } placement='bottom'>
                            <div className='guess'>
                            {/* C */}
                                {
                                    c_skillCorrect ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <defs>
                                    <linearGradient id="C" gradientTransform="rotate(90)">
                                        <stop offset="0%" stopColor="#00da49" />
                                        <stop offset="100%" stopColor="#fff" />
                                    </linearGradient>
                                    </defs>
                                    <text x="7" y="20" style={{ fontSize: '18px', fontWeight: '700' }}>
                                    <tspan fill="url(#C)">C</tspan>
                                    </text>
                                </svg>
                                    : <HelpOutlineIcon />
                                }
                            </div>
                        </Tooltip>
                    </Grow>
                </div>
            </Container>
            {
                colourCorrect && 
                weaponCorrect && 
                assistCorrect &&
                specialCorrect &&
                a_skillCorrect &&
                b_skillCorrect &&
                c_skillCorrect 
                    ?   <div>
                            <h2>Congratulations! You guessed the correct hero: {randomHero.title}</h2>
                            <PlayAgainButton />
                        </div>
                    : <></>
            }
        </>
    )
}

export default Guess;