import React from 'react'
import { Button } from '@mui/material'

function PlayAgainButton() {
    const handlePlayAgain = () =>{
        window.location.reload()
    }

    return (
        <Button className='mt-50' onClick={handlePlayAgain} variant="contained">Play Again</Button> 
    )
}

export default PlayAgainButton