import './App.css';
import { useState, createContext } from 'react'
import Guess from './components/Guess/Guess';
import GuessInput from './components/GuessInput/GuessInput';
import { randomHero } from './components/heroes';
import Footer from './components/Footer/Footer';
import PlayAgainButton from './components/PlayAgainButton/PlayAgainButton';

export const HeroContext = createContext()
export const CountContext = createContext()

function App() {

  // context
  const [guessCount, setGuessCount] = useState(0) // number of guesses
  const [heroGuess, setHeroGuess] = useState({ // hero object
    title: '', 
    colour: '',
    movement: '',
    weapon: '', 
    assist: '', 
    special: '', 
    A: '', 
    B: '', 
    C: ''
  })
  
  // array of guesses
  const items = [0, 1, 2, 3, 4, 5]

  return (
    <div className="App">
      <HeroContext.Provider value={[heroGuess, setHeroGuess]}>
        <CountContext.Provider value={[guessCount, setGuessCount]}>
        <GuessInput isVisible={guessCount <= 6 }/> 
          {
            guessCount <= 6 
            ? items.slice(0, guessCount).map((item) => ( <Guess key={item} /> ))
            : <div>
                <h2 style={{color: 'white', fontWeight: '400'}}>Game Over. The Correct hero was {randomHero.title}</h2>
                <PlayAgainButton />
              </div>
          }
        </CountContext.Provider>
      </HeroContext.Provider>
      <Footer />
    </div>
  );
}

export default App;
