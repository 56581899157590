// add disclaimer for weapon remixes and refines 

const colours = {
    red: '#880808', 
    blue: '#1F51FF', 
    green: '#50C878', 
    colourless: '#808080',
}
const assist = ['none', 'Rescue+', 'Fate Unchanged...', 'Rally Def/Res+', 'Nudge+', 'Gentle Dream+', 'Whimsical Dream', 'Frightful Dream', 'Sweet Dreams']
const weapons = {
    sword: 'Sword',
    axe: 'Axe',
    lance: 'Lance',
    tome: 'Tome',
    dagger: 'Dagger',
    bow: 'Bow',
    staff: 'Staff',
    breath: 'Dragon Breath',
    beast: 'Beast',    
}
const movement = {
    inf: 'Infantry',
    armored: 'Armored',
    cav: 'Cavalry',
    flyer: 'Flying'
}

const heroes = [

    // Asc
    { title: 'A!Fjorm', colour: colours.green, movement: movement.armored, weapon: weapons.axe, assist: assist[0], special: 'Ice Mirror', A: 'Steady Posture', B: 'Hardy Fighter 3', C: 'A/R Far Save 3' },
    { title: 'A!Laegjarn', colour: colours.colourless, movement: movement.cav, weapon: weapons.bow, assist: assist[0], special: 'Moonbow', A: 'Swift Sparrow 3', B: 'A/D Far Trace 3', C: 'Rouse Atk/Spd 4' },
    { title: 'A!Joshua', colour: colours.colourless, movement: movement.inf, weapon: weapons.dagger, assist: assist[0], special: 'Glimmer', A: 'Life and Death 4', B: 'Null Follow-Up 3', C: 'Green Feud 3' },
    { title: 'A!Ishtar', colour: colours.blue, movement: movement.inf, weapon: weapons.tome, assist: assist[0], special: 'Luna', A: 'Life and Death 4', B: 'Lull Spd/Res 3', C: "Time's Pulse 3" },
    { title: 'A!Eir', colour: colours.green, movement: movement.cav, weapon: weapons.dagger, assist: assist[0], special: 'Draconic Aura', A: 'Atk/Spd Catch 4', B: 'Mystic Boost 4', C: 'Sparkling Boost' },
    { title: 'A!Elincia', colour: colours.colourless, movement: movement.flyer, weapon: weapons.staff, assist: assist[1], special: "Light's Restraint", A: 'Atk/Spd Push 4', B: 'Dazzling Shift', C: 'Spd/Res Hold' },
    { title: 'A!Celica', colour: colours.colourless, movement: movement.inf, weapon: weapons.tome, assist: assist[0], special: 'Moonbow', A: 'Life and Death 4', B: 'Soul of Zofia', C: 'Inf. Null Follow 3' },
    { title: 'A!Tiki', colour: colours.red, movement: movement.inf, weapon: weapons.breath, assist: assist[0], special: 'Iceberg', A: 'Spd/Res Finish 4', B: 'Spd/Res Bulwark 3', C: 'All Together!' },
    { title: 'A!Hilda', colour: colours.green, movement: movement.inf, weapon: weapons.axe, assist: assist[0], special: 'Luna', A: 'Atk/Spd Solo 4', B: 'Velocity 3', C: 'Joint Drive Spd' },
    { title: 'A!Mareeta', colour: colours.red, movement: movement.inf, weapon: weapons.sword, assist: assist[0], special: 'Vital Astra', A: 'Atk/Spd Solo 4', B: 'Spurn 3', C: 'Even Tempest 3' },
    { title: 'A!Idunn', colour: colours.blue, movement: movement.armored, weapon: weapons.breath, assist: assist[0], special: 'Bonfire', A: 'Sturdy Stance 3', B: 'Wily Fighter 3', C: 'D/R Near Save 3' },
    { title: 'A!Florina', colour: colours.colourless, movement: movement.flyer, weapon: weapons.bow, assist: assist[0], special: 'Moonbow', A: 'Atk/Spd Push 4', B: 'S/D Far Trace 3', C: 'Joint Drive Spd' },
    
    // Rearmed
    { title: 'R!Alfred', colour: colours.blue, movement: movement.cav, weapon: weapons.lance, assist: assist[0], special: 'Bonfire', A: 'Self-Improver', B: 'Flow N Trace 3', C: 'Cross Spur Atk' },
    { title: 'R!Lif', colour: colours.red, movement: movement.cav, weapon: weapons.sword, assist: assist[0], special: 'Open the Future', A: 'Atk/Def Clash 4', B: 'Deadly Balance', C: 'Atk/Def Menace' },
    { title: 'R!Ophelia', colour: colours.red, movement: movement.inf, weapon: weapons.tome, assist: assist[0], special: 'Luna', A: 'Atk/Res Finish 4', B: 'Special Spiral 4', C: "Time's Pulse 3" },
    { title: 'R!Ganglöt', colour: colours.green, movement: movement.inf, weapon: weapons.axe, assist: assist[0], special: 'Iceberg', A: 'Distant Stance', B: 'Quick Riposte 4', C: 'Joint Dist. Guard' },
    { title: 'R!Robin', colour: colours.colourless, movement: movement.armored, weapon: weapons.breath, assist: assist[0], special: 'Pavise', A: 'Dragonhide', B: 'Hardy Fighter 3', C: 'A/S Near Save 3' },
    { title: 'R!Tana', colour: colours.colourless, movement: movement.flyer, weapon: weapons.bow, assist: assist[0], special: 'Deadeye', A: 'Atk/Spd Unity', B: 'Soaring Wings', C: 'Guidance 4' },
    { title: 'R!F!Chrom', colour: colours.red, movement: movement.cav, weapon: weapons.sword, assist: assist[2], special: 'Galeforce', A: 'Atk/Spd Clash 4', B: 'Spd/Def Snag 4', C: 'Inf. Spd Tactic' },
    { title: 'R!Ingrid', colour: colours.blue, movement: movement.flyer, weapon: weapons.lance, assist: assist[0], special: 'Luna', A: 'Knightly Devotion', B: 'Guard Bearing 4', C: 'Spd/Def Hold' },

    // Mythic Inf
    { title: 'Arval', colour: colours.colourless, movement: movement.inf, weapon: weapons.tome, assist: assist[0], special: 'Glacies', A: 'Duality', B: 'Atk/Res Tempo 3', C: 'Atk/Res Oath 4' },
    { title: 'Ash', colour: colours.colourless, movement: movement.inf, weapon: weapons.beast, assist: assist[0], special: 'Bonfire', A: 'Atk/Def Unity', B: 'Lull Atk/Def 3', C: 'Opening Retainer' },
    { title: 'Askr', colour: colours.colourless, movement: movement.inf, weapon: weapons.beast, assist: assist[0], special: 'Bonfire', A: 'Atk/Def Ideal 4', B: 'Atk/Def Bulwark 3', C: 'Opened Domain' },
    { title: 'Medeus', colour: colours.colourless, movement: movement.inf, weapon: weapons.breath, assist: assist[0], special: 'Glacies', A: 'Def/Res Ideal 4', B: 'Assured Rebirth', C: 'Canto Control 3' },
    { title: 'Bramimond', colour: colours.colourless, movement: movement.inf, weapon: weapons.tome, assist: assist[0], special: 'Luna', A: 'Atk/Def Push 4', B: 'Lull Atk/Res 3', C: 'Impenetrable Dark' },
    { title: 'Ullr', colour: colours.blue, movement: movement.inf, weapon: weapons.beast, assist: assist[0], special: 'Deadeye', A: 'Life and Death 4', B: 'Yngvi Ascendant', C: "Time's Pulse 3" },
    { title: 'Mila', colour: colours.colourless, movement: movement.inf, weapon: weapons.breath, assist: assist[3], special: 'none', A: 'Bracing Stance 3', B: 'Dragon Wall 3', C: "Mila's Turnwheel II" },
    { title: 'Thrasir', colour: colours.green, movement: movement.inf, weapon: weapons.tome, assist: assist[0], special: 'Moonbow', A: 'Atk/Spd Ideal 4', B: 'Killing Intent+', C: 'Panic Smoke 3' },
    { title: 'Sothis', colour: colours.red, movement: movement.inf, weapon: weapons.breath, assist: assist[0], special: 'Sirius+', A: 'Atk/Spd Solo 3', B: 'Null C-Disrupt 3', C: "Time's Pulse 3" },
    { title: 'Gotoh', colour: colours.colourless, movement: movement.inf, weapon: weapons.tome, assist: assist[0], special: 'Iceberg', A: 'Gift of Magic', B: 'Guard 4', C: 'Joint Dist. Guard' },
    { title: 'Líf', colour: colours.red, movement: movement.inf, weapon: weapons.sword, assist: assist[0], special: 'Open the Future', A: 'Distant Counter', B: 'Deadly Balance+', C: "Time's Pulse 3" },
    { title: 'Dagr', colour: colours.green, movement: movement.inf, weapon: weapons.axe, assist: assist[0], special: 'Dragon Fang', A: 'Atk/Spd Push 4', B: 'Sun-Twin Wing', C: 'Even Tempest 3' },
    { title: 'Ashera', colour: colours.colourless, movement: movement.inf, weapon: weapons.tome, assist: assist[0], special: 'Glacies', A: 'Atk/Res Ideal 4', B: 'Lull Spd/Res 3', C: "Order's Restraint" },
    { title: 'Seiros', colour: colours.blue, movement: movement.inf, weapon: weapons.breath, assist: assist[0], special: 'Glacies', A: 'Mirror Impact', B: 'Dragon Wall 3', C: 'Wings of Light' },

    // Mythic Cav
    { title: 'Reginn', colour: colours.red, movement: movement.cav, weapon: weapons.sword, assist: assist[0], special: 'Seiðr Shell', A: 'Swift Sparrow 3', B: 'Lull Spd/Def 3', C: 'Panic Smoke 3' },
    { title: 'Ótr', colour: colours.green, movement: movement.cav, weapon: weapons.axe, assist: assist[0], special: 'Brutal Shell', A: 'G Duel Cavalry 4', B: 'Flow Refresh 3', C: 'Atk/Def Menace' },
    { title: 'Seiðr', colour: colours.green, movement: movement.cav, weapon: weapons.tome, assist: assist[0], special: 'Iceberg', A: 'Still Water 4', B: 'Chill Atk/Res 3', C: 'Future-Focused' },
    { title: 'Nerþuz', colour: colours.blue, movement: movement.cav, weapon: weapons.beast, assist: assist[0], special: 'Galeforce', A: 'Atk/Spd Clash 4', B: 'Fruit of Life', C: 'Alarm Atk/Spd' },
    { title: 'Freyja', colour: colours.green, movement: movement.cav, weapon: weapons.beast, assist: assist[0], special: 'Luna', A: 'Atk/Spd Solo 4', B: 'Binding Necklace', C: 'Pulse Smoke 3' },

    // Mythic Flier
    { title: 'Elimine', colour: colours.colourless, movement: movement.flyer, weapon: weapons.staff, assist: assist[4], special: 'none', A: 'Atk/Res Push 4', B: 'A/S Far Trace 3', C: 'Holy Ground' },
    { title: 'Embla', colour: colours.red, movement: movement.flyer, weapon: weapons.beast, assist: assist[0], special: 'Draconic Aura', A: 'Atk/Spd Clash 4', B: 'Beast Agility 3', C: 'Severance' },
    { title: 'Thorr', colour: colours.green, movement: movement.flyer, weapon: weapons.axe, assist: assist[0], special: 'Bonfire', A: 'Atk/Def Push 4', B: 'Flow Guard 3', C: 'Worldbreaker' },
    { title: 'Yune', colour: colours.green, movement: movement.flyer, weapon: weapons.tome, assist: assist[0], special: 'Glacies', A: 'Atk/Res Unity', B: 'Sabotage Res 3', C: 'Chaos Named+' },
    { title: 'Peony', colour: colours.blue, movement: movement.flyer, weapon: weapons.tome, assist: assist[5], special: 'none', A: 'B Duel Flying 3', B: 'Aerobatics 3', C: 'Cross Spur Res' },
    { title: 'Mirabilis', colour: colours.red, movement: movement.flyer, weapon: weapons.tome, assist: assist[6], special: 'none', A: 'Fort. Def/Res 3', B: 'Sabotage Def 3', C: 'Def/Res Gap 3' },
    { title: 'Triandra', colour: colours.green, movement: movement.flyer, weapon: weapons.tome, assist: assist[7], special: 'none', A: 'Atk/Spd Push 4', B: 'Aerobatics 3', C: 'Spd/Res Rein 3' },
    { title: 'Altina', colour: colours.red, movement: movement.flyer, weapon: weapons.sword, assist: assist[0], special: 'Twin Blades', A: "Ashera's Chosen+", B: 'Vantage 3', C: 'Atk/Def Hold' },
    { title: 'Plumeria', colour: colours.red, movement: movement.flyer, weapon: weapons.tome, assist: assist[8], special: 'none', A: "Atk/Res Push 4", B: 'Sabotage Spd 3', C: 'Atk/Res Rein 3' },
    { title: 'Hel', colour: colours.green, movement: movement.flyer, weapon: weapons.axe, assist: assist[0], special: 'Luna', A: "Distant Counter", B: 'Guard Bearing 3', C: 'Inevitable Death' },
    { title: 'Eir', colour: colours.colourless, movement: movement.flyer, weapon: weapons.dagger, assist: assist[0], special: 'Iceberg', A: "Atk/Res Unity", B: 'Mystic Boost 3', C: 'Sparkling Boost+' },
    { title: 'Naga', colour: colours.blue, movement: movement.flyer, weapon: weapons.breath, assist: assist[0], special: 'Luna', A: "AR-O Atk/Spd 3", B: 'Dive-Bomb 3', C: 'Divine Fang+' },

    // Mythic Armor
    { title: 'Fomortiis', colour: colours.colourless, weapon: weapons.beast, assist: assist[0], special: 'Bonfire', A: 'Nightmare', B: 'Beast Follow-Up 3', C: 'A/D Far Save 3' },
    { title: 'Duma', colour: colours.colourless, weapon: weapons.breath, assist: assist[0], special: 'Draconic Aura', A: 'Fury 4', B: 'Bold Fighter 3', C: 'Upheaval+' },
]

const randomHero = heroes[Math.floor(Math.random()*heroes.length)]

export { heroes, randomHero }