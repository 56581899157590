import React from "react";
import "./style.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import { heroes } from "../heroes";
import { randomHero } from "../heroes";
import { useContext, useState } from "react";
import { CountContext, HeroContext } from "../../App";

/**
 * The input box for user guesses
 * App logic is also handled here
 * and passed with context
 */

function GuessInput({ isVisible }) {
  // localstate
  const [correctBorder, setCorrectBorder] = useState("");

  // context
  const [heroGuess, setHeroGuess] = useContext(HeroContext);
  const [guessCount, setGuessCount] = useContext(CountContext);

  let handleSubmit = (e) => {
    e.preventDefault();

    setGuessCount(() => guessCount + 1);

    // if correct guess -> green input bar, else -> red
    if (heroGuess) {
      JSON.stringify(heroGuess.title) === JSON.stringify(randomHero.title)
        ? setCorrectBorder("green")
        : setCorrectBorder("red");
    } else {
      setCorrectBorder("red");
    }

    // set heroGuess to it's corresponding object
    if (heroGuess) {
      const findHero = heroes.find((item) => item.title === heroGuess.title);
      setHeroGuess(() => findHero);
    }
  };

  return (
    <div className="header">
      <h1>Welcome to FEHdle!</h1>
      
      {isVisible ? (
        <>
          <h2>Guess The Unit: </h2>
          <form onSubmit={handleSubmit} className="mobile-input">
            <Autocomplete
              freeSolo
              id="combo-box-demo"
              options={heroes.map((option) => option.title)}
              className="center-screen mb-20"
              sx={{ width: 500, "@media (max-width: 428px)": { width: "80%" } }}
              renderInput={(params) => <TextField {...params} />}
              name="heroName"
              onChange={(e) => setHeroGuess({ title: e.target.innerText, movement: heroGuess.movement })}
              style={{ background: `${correctBorder}` }}
            />
            <Button type="submit" className="mt-50" variant="contained">
              Guess
            </Button>
          </form>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default GuessInput;
